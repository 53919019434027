<template>
    <section id="clients">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">{{ $t('tea_recommendation') }}</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-6 portfolio-item" v-for="obj in tea" :key="obj">
                    <a class="portfolio-link" data-toggle="modal" v-bind:href="'#portfolioModal' + key">
                        <div class="portfolio-hover">
                            <div class="portfolio-caption">
                                <h4>{{ $t(obj.content) }}</h4>
                            </div>
                        </div>
                        <img class="img-fluid" src="@/assets/img/tea/tea_20.jpeg" alt="">
                    </a>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'EvaluationTest',
        data() {
            return {
                body_type: null,
                tea: [],
            }
        },
        methods: {
            async getTeaChoices(body_type_id, preference) {
                if(body_type_id == null || preference == null) {
                    this.$router.push({
                        name: "evaluation",
                        params: {}
                    });
                    return
                }

                try {
                    let response = await this.$http.get(
                        process.env.VUE_APP_AI_TEA_TECH_BACKEND_HOST + "tea?bodyTypeID=" + body_type_id + 
                        "&preference=" + preference,
                    );
                    this.tea = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },
        created() {
            let body_type_id = this.$route.params.body_type_id;
            let preference = this.$route.params.preference;
            this.getTeaChoices(body_type_id, preference);
        }
    };
</script>
